/*
  Resume Website project
  Author: Dan J. Hamilton
  Date:   15 February 2016

  Filename:         index.html
  Supporting files: modernizr-1.5.js, styles_main.css
*/
import React from "react";
import { Link } from "gatsby";

import SEO from "../../components/seo";
import Image from "../../components/image";
import "../../styles/resume_styles.css";

export default () => (
  <>
    <SEO title="Resume of Dan J. Hamilton" />

    <div className="all-content">
      <div className="nav">
        <ul>
          <li><Link to="/index-project/" title="Project Index">Project Index</Link></li>
          <li><Link to="/resume/" title="Welcome Page">Welcome Page</Link></li>
          <li><Link to="/resume/employmenthistory/" title="Resume">Resume</Link></li>
          <li><Link to="/resume/bio/" title="Biography">Biography</Link></li>
          <li><Link to="/resume/music/" title="Favorite Music">Favorite Music</Link></li>
          <li><Link to="/resume/food/" title="Favorite Food">Favorite Food</Link></li>
          <li><Link to="/resume/about/" title="About this Website">About this Website</Link></li>
        </ul>
        <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }} title="Linguini with White Clam Sauce">
          <Image className="" value="linguini" alt="Linguini with White Clam Sauce" />
        </div>
      </div>

      <section className="resume-main-content">
        <div className="section-header">
          <h1>Favorite Dish</h1>
        </div>

        <p id="firstp">   
        I like to cook.  But let's set our expectations appropriately, I am a home chef - I cook to please my sense of taste (and hopefully the majority of my family).  
          With that in mind, the "quantities" listed herein are guidelines.  You are free to use more or less of any.  Baking of bread, pastries, cookies. etc. is about the only area where I get out the measuring tools.
        </p>

        <table id="ingredients" border="5" align="center">
          <thead>
          <tr>
            <th>Quantity</th>
            <th>Ingrediants</th>
          </tr>  
          </thead>
          <tbody>
          <tr>
            <td>2 medium cloves</td>
            <td>Garlic</td>
          </tr>
          <tr>
            <td>1 medium onion</td>
            <td>Onion</td>
          </tr>
          <tr>
            <td>2-3 T.</td>
            <td>Olive Oil</td>
          </tr>
          <tr>
            <td>1/2 tsp.</td>
            <td>Salt</td>
          </tr>
          <tr>
            <td>1/2 tsp.</td>
            <td>Pepper</td>
          </tr>
          <tr>
            <td>1 tsp.</td>
            <td>Oregano</td>
          </tr>
          <tr>
            <td>4 6.5 oz. cans</td>
            <td>Clams - Minced or Chopped</td>
          </tr>
          <tr>
            <td>3/4 pound</td>
            <td>Linguini</td>
          </tr>
          </tbody>
        </table>
		 
		    <h3>Cooking Directions</h3>

		    <ol>
          <li>In a medium saucepan, sweat onions and garlic in olive oil.</li>
          <li>Add clams, pepper, salt, oregano, and parsley.  Bring to a low boil.</li>
          <li>Reduce heat to a steady simmer.</li>
          <li>In a large pot, salt and oil water to cook pasta.  Bring to a boil.</li>
          <li>Add linguini and cook to your taste (I like al dente or still slightly firm).</li>
          <li>Sample the clam sauce while cooking testing for flavor and integration of ingredients.  Adjust (salt, pepper, oregano, water, etc.) until you arrive at a flavor profile you enjoy.</li>
          <li>There are basically two approaches to finishing the pasta cook:
            <ul>
              <li>Cook fully to desired firmness, remove from heat and rinse with cold water to stop the cooking process, or</li>
              <li>Remove from heat slightly prior to reaching desired firmness and let stand to complete cooking.  This is the same concept as letting meat stand for 15 minutes after taking it out of the oven and prior to carving.</li>
            </ul>
          </li>
          <li>When the clam sauce meets your approval, plate the pasta and top with the sauce.</li>
          <li>Call the family to the table and enjoy!  (Should serve 4-5 people.)</li>
		    </ol>
      </section>
 
      <div className="footer">
         <div className="address">
            Winter Garden, FL &nbsp;34787
         </div>
      </div>
    </div>
  </>
)